





































































































































import BrandLogo from '@/components/branding/BrandLogo.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { useLogin } from '@/composition/login'
import i18n from '@/setup/i18n'
import router, { currentRoute } from '@/router'
import { RawLocation } from 'vue-router'
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Login',

  components: {
    BrandLogo,
    ValidationObserver,
    ValidationProvider,
  },

  setup() {
    const usernameLabel = computed(
      () => i18n.t('loginForm.username.label') as string
    )

    const passwordLabel = computed(
      () => i18n.t('loginForm.password.label') as string
    )

    const rememberMeLabel = computed(
      () => i18n.t('loginForm.rememberMe.label') as string
    )

    const { model, submit, isSubmitting, error } = useLogin({
      onSuccess: () => {
        // Redirect to the originally requested page, or to the dashboard page
        const routeTo: RawLocation =
          currentRoute.value &&
          typeof currentRoute.value.query.redirectedFrom === 'string'
            ? currentRoute.value.query.redirectedFrom
            : { name: 'Dashboard' }
        router.push(routeTo)
      },
      onError: () => {},
    })

    return {
      model,
      submit,
      isSubmitting,
      error,
      usernameLabel,
      passwordLabel,
      rememberMeLabel,
    }
  },
})
